import React, { useState, useEffect } from "react";
import {
  getArticles,
  getArticleDetails,
  getJournals,
  getP1Messages,
  getLastYearArticles,
  getEligibilityDetails,
} from "../../helpers";
import { itemsPerPage, allFilterOption } from "../../constants";
import { MDBCol, MDBRow } from "mdbreact";
import { userSettings } from "../../user-settings";
import {
  BarChart,
  ArticleDetailsModal,
  Card,
  PieChart,
} from "pubtrack-frontend-library";
import ArticlesTable from "../../components/ArticlesTable";

const Published = () => {
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [articleDetails, setArticleDetails] = useState(null);
  const [eligibilityDetails, setEligibilityDetails] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [articles, setArticles] = useState([]);
  const [institutionsPercentage, setInstitutionsPercentage] = useState([]);
  const [tabs, setTabs] = useState(userSettings.details);

  const columns = React.useMemo(
    () => [
      {
        Header: "Manuscript ID",
        accessor: "manuscript_id",
        sortable: true,
      },
      {
        Header: "DOI",
        accessor: "doi",
        sortable: true,
      },
      {
        Header: "Journal title",
        accessor: "journal",
        sortable: true,
      },
      {
        Header: "Manuscript title",
        accessor: "manuscript_title",
        sortable: true,
      },
      {
        Header: "Last event",
        accessor: "lastevent",
        sortable: false,
      },
    ],
    []
  );

  const addAllFilterOption = (arr) => {
    const isPresent = !!arr.find((item) => item.title === "All journals");
    if (!isPresent) {
      arr.unshift(allFilterOption);
    }
    return arr;
  };

  const checkShowEligibility = () => {
    if (eligibilityDetails && !Array.isArray(eligibilityDetails)) {
      const newTabs = tabs.map((tab) =>
        tab.label === "Eligibility" ? { ...tab, display: true } : tab
      );
      setTabs(newTabs);
    }
  };

  useEffect(() => {
    getLastYearArticles().then((res) => setArticles(res.data));

    getP1Messages().then((res) => setInstitutionsPercentage(res.data));
  }, []);

  useEffect(() => {
    if (selectedMessage) {
      getArticleDetails(selectedMessage)
        .then((res) => setArticleDetails(res.data))
        .then(setIsOpen(true))
        .then(setSelectedMessage(null));

      getEligibilityDetails(selectedMessage)
        .then((res) =>
          setEligibilityDetails({
            header: { ...res.data?.header[0] },
            flow: [...res.data?.flow],
          })
        )
        .then(setIsOpen(true));
    }
  }, [selectedMessage]);

  useEffect(() => {
    checkShowEligibility();
  }, [eligibilityDetails]);

  const getArticlesData = ({
    searchValue,
    offset,
    itemsPerPage,
    sortBy,
    sortDir,
    filterValue,
    ror,
  }) => {
    return getArticles(
      searchValue,
      offset,
      itemsPerPage,
      sortBy,
      sortDir,
      "published",
      filterValue,
      ror
    ).then((res) => ({ data: res.data.articles, total: res.data.total }));
  };

  const getFilterData = () => {
    return getJournals().then((res) => addAllFilterOption(res.data));
  };

  const onSelectRow = (row) => {
    const messageId = row && row.original ? row.original["id"] : null;
    setSelectedMessage(messageId);
  };

  return (
    <div className="container">
      <MDBRow className="mt-5 d-flex justify-content-center">
        <MDBCol md="8" className="mb-5 mt-4">
          <Card
            title="Published articles per month:"
            content={
              <BarChart
                data={articles}
                xAxisKey="month"
                yAxisKey="count"
                color={"colorsPalette"}
              />
            }
          />
        </MDBCol>
        <MDBCol md="4" className="mb-5 mt-4">
          <Card
            title="With/without P1:"
            content={
              <PieChart
                data={institutionsPercentage}
                labelKey="label"
                valueKey="count"
              />
            }
          />
        </MDBCol>
      </MDBRow>

      <div className="mb-5">
        <ArticlesTable
          columns={columns}
          itemsPerPage={itemsPerPage}
          getTableData={getArticlesData}
          getFilterData={getFilterData}
          onSelectRow={onSelectRow}
          sortByDefault={"created"}
          sortDirDefault={"desc"}
        />
      </div>

      <ArticleDetailsModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        articleDetails={articleDetails}
        tabItems={tabs}
        eligibilityDetails={eligibilityDetails}
      />
    </div>
  );
};

export default Published;
