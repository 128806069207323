import axios from 'axios';
import { ApiBaseUrl, ApiPubtrackPath, ApiOasbUrl } from '../constants';
import { token as TOKEN } from 'pubtrack-frontend-library';


const axiosInstance = axios.create({
    baseURL: `${ApiBaseUrl}${ApiPubtrackPath}`
});

const axiosOasbInstance = axios.create({
    baseURL: `${ApiOasbUrl}`
});

axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem(TOKEN);
    config.headers.Authorization = `Bearer ${token}`;
    config.headers["Content-Type"] = 'application/json';
    return config;
});

axiosOasbInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem(TOKEN);
    config.headers.Authorization = `Bearer ${token}`;
    config.headers["Content-Type"] = 'application/json';
    return config;
});

export { axiosInstance, axiosOasbInstance };