import React from 'react'
import { NavigationTabs, CustomTable } from 'pubtrack-frontend-library'
import { getDeals } from '../../helpers';
import { dealsColumns } from './columnsData'
import { itemsPerPage } from '../../constants';

const Deals = () => {
    const getDealsData = async ({ searchValue, offset, itemsPerPage, sortBy, sortDir }) => {
        return getDeals(searchValue, offset, itemsPerPage, sortBy, sortDir)
            .then(res => ({ data: res.data.deals, total: res.data.total }))
    };

    const tabs = [
        {
            id: '1',
            title: 'Management',
            content: (
                <CustomTable
                    columns={dealsColumns}
                    itemsPerPage={itemsPerPage}
                    getTableData={getDealsData}
                    sortByDefault="id"
                    sortDirDefault="desc"
                    search={true}
                />
            ),
        },
    ];

    return (
        <div className="container">
            <div className="mt-5 pb-3">
                <NavigationTabs tabs={tabs} />
            </div>
        </div>
    )
}

export default Deals;