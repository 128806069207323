import React, { useState } from "react";
import { MDBBtn, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import AdminEditDealModal from "./AdminEditDealModal";
import AdminCreateDealModal from "./AdminCreateDealModal";
import { DataTableV2, Dialog } from "pubtrack-frontend-library";
import { withTheme } from "styled-components";
import useAdminDeals from "./hooks/useAdminDeals";
import { columns } from "./columns";

const AdminDeals = () => {
  const [itemToDeleteId, setItemToDeleteId] = useState(null);
  const [itemToEditId, setItemToEditId] = useState(null);
  const [isCreateDealModalOpen, setIsCreateDealModalOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);

  const { data, handleDeleteDeal, fetchData } = useAdminDeals({
    currentPage: currentPage,
  });

  const mappedData = data?.map((deal) => {
    return {
      ...deal,
      directbilling: deal?.directbilling ? (
        <MDBIcon icon="check" color="success" />
      ) : (
        <MDBIcon icon="close" color="danger" />
      ),
      maildomain: deal?.maildomain
        ? deal.maildomain.length > 50
          ? `${deal.maildomain.substring(0, 50)}...`
          : deal.maildomain
        : null,
      editAction: (
        <div key={deal?.id} onClick={(e) => e.stopPropagation()}>
          <MDBBtn floating onClick={() => setItemToEditId(deal?.id)}>
            <MDBIcon icon="edit" />
          </MDBBtn>
        </div>
      ),
      deleteAction: (
        <div key={deal?.id} onClick={(e) => e.stopPropagation()}>
          <MDBBtn
            color="danger"
            floating
            onClick={() => setItemToDeleteId(deal?.id)}
          >
            <MDBIcon icon="trash-alt" />
          </MDBBtn>
        </div>
      ),
    };
  });

  return (
    <div className="container my-5">
      <Dialog
        onConfirm={() => handleDeleteDeal(itemToDeleteId)}
        isOpen={!!itemToDeleteId}
        setIsOpen={setItemToDeleteId}
        dialogText={"Are you sure you want to delete this deal?"}
        dialogTitle="Delete deal"
      />

      <AdminEditDealModal
        itemId={itemToEditId}
        onClose={() => setItemToEditId(null)}
        onSuccessSubmit={fetchData}
      />

      <AdminCreateDealModal
        isOpen={isCreateDealModalOpen}
        onClose={() => setIsCreateDealModalOpen(false)}
        onSuccessSubmit={fetchData}
      />
      <MDBRow className="mb-2">
        <MDBCol className="text-end">
          <MDBBtn
            size="md"
            onClick={() => setIsCreateDealModalOpen(true)}
            disabled={false}
          >
            <MDBIcon icon="plus-circle" className="me-2" />
            New deal
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <DataTableV2 columns={columns} rows={mappedData} />
    </div>
  );
};

export default withTheme(AdminDeals);
