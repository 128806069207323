import { toast } from "react-toastify";
import {
  notificationsTime,
  errorMessage as defaultErrorMessage,
  successMessage as defaultSuccessMessage,
} from "../constants";
export const changeFormatDate = (date) => {
  return date
    ? [date.getFullYear(), date.getMonth() + 1, date.getDate()]
        .map((date) => (date < 10 ? `0${date}` : date))
        .join("-")
    : "";
};

export const changeFormatTime = (date) => {
  const ampm = date.getHours() >= 12 ? "PM" : "AM";
  const hours = date.getHours() >= 12 ? date.getHours() - 12 : date.getHours();
  const minutes = ("0" + date.getMinutes()).slice(-2);
  return `${hours}:${minutes} ${ampm}`;
};

export const transformDate = (date) => {
  let res = null;
  if (date) {
    const created = new Date(date);
    res = `${changeFormatDate(created)}`;
  }
  return res;
};

export const transformData = (data) => {
  return { article: { ...data } };
};

export const showSuccessToast = (successMessage = defaultSuccessMessage) => {
  return toast.success(successMessage, {
    position: "top-right",
    autoClose: notificationsTime,
  });
};

export const showErrorToast = (error) => {
  let errorMessage = error || defaultErrorMessage;

  if (error instanceof Object) {
    const responseData = error?.response?.data;
    errorMessage =
      responseData?.errorMessage?.message ||
      responseData?.errorMessage ||
      error.message;
  }

  return toast.error(errorMessage, {
    position: "top-right",
    autoClose: notificationsTime,
  });
};
