import { MDBBtn } from "mdbreact";

const customBtnStyles = {
  display: "block",
  padding: 0,
  margin: 0,
  textTransform: "lowercase",
};

const RORCell = ({ row }) => {
  const handleButtonClick = (e) => {
    e.stopPropagation();
    window.open(row.original?.ror, "_blank");
  };

  const ror = row.original?.ror;

  return (
    <>
      {ror && (
        <MDBBtn
          style={customBtnStyles}
          color="link"
          rippleColor="light"
          onClick={handleButtonClick}
        >
          {ror}
        </MDBBtn>
      )}
    </>
  );
};

const DirectBillingCell = ({ row }) => {
  const directBilling = row?.original?.directbilling;
  return (
    <>
      {directBilling !== undefined && (
        <span>
          {directBilling ? (
            <i className="fas fa-check"></i>
          ) : (
            <i className="fas fa-times"></i>
          )}
        </span>
      )}
    </>
  );
};

export const dealsColumns = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Institution",
    accessor: "institution",
  },
  {
    Header: "ROR",
    Cell: RORCell,
  },
  {
    Header: "Direct billing",
    Cell: DirectBillingCell,
  },
];
