import React from "react";
import { MDBIcon, MDBBtn, MDBFormInline } from "mdbreact";
import styled, { withTheme } from "styled-components";

const SearchPanelWrapper = styled.div`
  .form-control:focus {
    box-shadow: none !important;
    border-bottom: 2px solid ${(props) => props.theme.primaryColor} !important;
  }

  .md-form {
    display: flex;
    align-items: center;
    margin: 0;
  }

  .form-control {
    flex: 1;
    margin-right: 0.5rem;
  }

  .btn-search-button-color {
    background-color: ${(props) =>
      props.theme.searchButtonBackgroundColor} !important;
  }
`;

const SearchPanel = ({
  onSearch,
  searchValue,
  setSearchValue,
  placeholder = "Search",
}) => {
  const onSubmit = (event) => {
    event.preventDefault();
    onSearch();
  };

  const onInput = (event) => {
    setSearchValue(event.target.value);
  };

  return (
    <SearchPanelWrapper>
      <MDBFormInline className="md-form" onSubmit={onSubmit}>
        <input
          value={searchValue}
          onChange={onInput}
          className="form-control mb-0"
          type="text"
          placeholder={placeholder}
        />

        <MDBBtn
          size="sm"
          color="search-button-color"
          className="py-2 px-3 text-light"
          type="submit"
        >
          <MDBIcon size="lg" icon="search" />
        </MDBBtn>
      </MDBFormInline>
    </SearchPanelWrapper>
  );
};

export default withTheme(SearchPanel);
