export const columns = [
  {
    label: "ID",
    field: "id",
  },
  {
    label: "Name",
    field: "name",
  },
  {
    label: "Institution",
    field: "institution",
  },
  {
    label: "ROR",
    field: "ror",
  },
  {
    label: "Mail Domain",
    field: "maildomain",
  },
  {
    label: "Deal ID",
    field: "dealid",
  },
  {
    label: "Direct Billing",
    field: "directbilling",
  },
  {
    field: "editAction",
    label: "",
  },
  {
    field: "deleteAction",
    label: "",
  },
];
