import { axiosInstance } from "./axiosSetup";
import { handleErrors } from "pubtrack-frontend-library";


export async function getInstitutionDataPerMonth(id) {
    return await axiosInstance
        .get(`tracker/messages/monthly?ror=${id}`)
        .catch(handleErrors)
};

export async function getInstitutionJournals(id) {
    return await axiosInstance
        .get(`tracker/reports/institution/articles/count/per-journal?ror=${id}`)
        .catch(handleErrors)
};

export async function getInstitutionMessages(id, searchValue, offset, perPage, sortBy, sortDir) {
    return await axiosInstance
        .get(`tracker/messages/oasb/list?ror=${id}&query=${searchValue}&startrow=${offset}&maxrows=${perPage}&orderby=${sortBy}&orderdir=${sortDir}`)
        .catch(handleErrors)
};

export async function getP1Messages() {
    return await axiosInstance
        .get(`tracker/reports/articles/messages`)
        .catch(handleErrors)
};

export async function getFunderArticlesLastYear(id) {
    return await axiosInstance
        .get(`tracker/reports/funder/articles/last-year?ror=${id}`)
        .catch(handleErrors)
};

export async function getArticlesPerInstitution() {
    return await axiosInstance
        .get(`tracker/reports/institutions/articles`)
        .catch(handleErrors)
};
export async function getArticlesPerFunder() {
    return await axiosInstance
        .get(`tracker/reports/funders/articles`)
        .catch(handleErrors)
};

export async function getFunderJournals(id) {
  return await axiosInstance
      .get(`tracker/reports/funder/articles/count/per-journal?ror=${id}`)
      .catch(handleErrors)
};

export async function getInstitutionArticles(rorId, type) {
    return await axiosInstance
        .get(`tracker/reports/institution/articles?ror=${rorId}&selection=${type}`)
        .catch(handleErrors)
};

export async function getFunderArticles(rorId, type) {
    return await axiosInstance
        .get(`tracker/reports/funder/articles?ror=${rorId}&selection=${type}`)
        .catch(handleErrors)
}