export const ApiBaseUrl = `${process.env.REACT_APP_API_BASE_URL}`;
export const ApiOasbUrl = `${process.env.REACT_APP_API_AUTH_URL}`;
export const ApiPubtrackPath = `${process.env.REACT_APP_API_PUBTRACK_PATH}`;
export const publisher_id = `${process.env.REACT_APP_PUBLISHER_ID}`;

export const itemsPerPage = 10;

export const allFilterOption = {
  issn: "",
  title: "All journals",
  id: "All journals",
};

export const errorMessage = "Error, something went wrong";
export const successMessage = "Action completed successfully";
export const notificationsTime = 3000;
