import React from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import Login from "./pages/login/Login";
import Payoff from "./components/Payoff";
import Published from "./pages/published/Published";
import Pipeline from "./pages/pipeline/Pipeline";
import { userSettings } from "./user-settings";
import { Navbar, token, findFirstItem } from "pubtrack-frontend-library";
import Institutions from "./pages/institutions/Institutions";
import Funders from "./pages/funders/Funders";
import Messages from "./pages/messages/Messages";
import Deals from "./pages/deals/Deals";
import Eligibility from "./pages/eligibility/Eligibility";
import AdminDeals from "./pages/admin-deals/AdminDeals";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const authorized = localStorage.getItem(token);
  const { push, location } = useHistory();
  const isAdminMode = location.pathname.includes("admin-");

  const handleAdminModeSwitch = () => {
    if (authorized) {
      const targetPath = !isAdminMode ? "/admin-deals" : "/pipeline";
      push(targetPath);
    }
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        const isAdminMode = props.location.pathname.includes("admin-");
        return authorized ? (
          <>
            <Payoff />
            <Navbar
              {...props}
              navItems={
                isAdminMode ? userSettings.adminNavbar : userSettings.navbar
              }
              isAdminSwitchEnabled
              isAdminMode={isAdminMode}
              onAdminModeSwitch={handleAdminModeSwitch}
            />
            <Component {...props} />
          </>
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
};

const Routes = () => {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <PrivateRoute path="/pipeline" component={Pipeline} />
      <PrivateRoute path="/published" component={Published} />
      <PrivateRoute path="/messages" component={Messages} />
      <PrivateRoute path="/institutions" component={Institutions} />
      <PrivateRoute path="/funders" component={Funders} />
      <PrivateRoute path="/deals" component={Deals} />
      <PrivateRoute path="/eligibility" component={Eligibility} />
      <PrivateRoute path="/admin-deals" component={AdminDeals} />
      <Redirect from="/" to={findFirstItem(userSettings.navbar, "path")} />
    </Switch>
  );
};

export default Routes;
