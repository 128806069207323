import { axiosInstance } from "./axiosSetup";
import { handleErrors } from "pubtrack-frontend-library";


export async function getMessages(searchValue, offset, perPage, sortBy, sortDir, type, ror = "") {
    return await axiosInstance
        .get(`tracker/messages/oasb/list?query=${searchValue}&startrow=${offset}&maxrows=${perPage}&orderby=${sortBy}&orderdir=${sortDir}&type=${type}&ror=${ror}`)
        .catch(handleErrors)
};

export async function getMessageDetails(id) {
    return await axiosInstance
        .get(`tracker/messages/oasb/${id}`)
        .catch(handleErrors)
};

export async function getMessagesPerMonth() {
    return await axiosInstance
        .get(`tracker/messages/oasb/monthly`)
        .catch(handleErrors)
};

export async function getMessagesTypes() {
    return await axiosInstance
        .get(`tracker/messages/oasb/types`)
        .catch(handleErrors)
};
