import { transformDate } from "pubtrack-frontend-library";

const inProcessEligibilitiesColumns = [
    {
        Header: 'Recipient',
        accessor: 'name',
        sortable: false
    },
    {
        Header: 'Since',
        accessor: 'days_past',
        sortable: false
    },
    {
        Header: 'Position',
        accessor: 'position',
        sortable: false
    },
]

const historicEligibilitiesColumns = [
    {
        Header: 'Article',
        accessor: 'article',
        sortable: false
    },
    {
        Header: 'Result',
        accessor: 'state',
        sortable: false
    },
]

const responseEligibilitiesColumns = [
    {
        Header: 'Institution / Funder',
        accessor: 'name',
        sortable: false
    },
    {
        Header: 'Request Count',
        accessor: 'requests',
        sortable: false
    },
]

const eligibilityDetailsColumns = [
    {
        Header: 'Recipient',
        accessor: 'name',
        sortable: false
    },
    {
        Header: 'Status',
        accessor: 'state',
        sortable: false
    },
    {
        Header: 'Date',
        Cell: ({ row }) =>
            <span>{transformDate(row.original.dispatched) || "-"}</span>,
        sortable: false
    },
]

export { eligibilityDetailsColumns, historicEligibilitiesColumns, responseEligibilitiesColumns, inProcessEligibilitiesColumns }