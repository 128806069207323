import React from "react";
import { MDBCol, MDBInput, MDBRow, MDBSwitch } from "mdb-react-ui-kit";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAdminDeals from "./hooks/useAdminDeals";
import {
  FormModalWrapper,
  loadRorOptions,
  AsyncInput,
} from "pubtrack-frontend-library";

const AdminCreateDealModal = ({ onClose, isOpen, onSuccessSubmit }) => {
  const validationSchema = Yup.object({
    directbilling: Yup.boolean().required("Direct billing is required"),
    name: Yup.string().required("Name is required"),
    institution: Yup.string().required("Institution is required"),
    ror: Yup.string().required("ROR is required"),
    maildomaion: Yup.string().notRequired(),
    dealid: Yup.string().notRequired(),
  });

  const handleCloseModal = () => {
    onClose();
    formik.resetForm();
  };

  const { handleCreateDeal } = useAdminDeals({ doInitialFetch: false });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      directbilling: false,
      name: "",
      institution: "",
      ror: "",
      maildomain: "",
      dealid: "",
    },
    onSubmit: async (values) => {
      try {
        await handleCreateDeal(values);
        handleCloseModal();
        await onSuccessSubmit?.();
      } catch (error) {}
    },
  });

  return (
    <FormModalWrapper
      title="Create Deal"
      isOpen={isOpen}
      isLoading={formik.isSubmitting}
      onSubmit={formik.handleSubmit}
      onClose={handleCloseModal}
    >
      <MDBRow className="mb-3">
        <MDBCol md={6}>
          <MDBInput
            id="name"
            label="Name"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          {formik.errors.name ? (
            <div className="text-danger">{formik.errors.name}</div>
          ) : null}
        </MDBCol>
        <MDBCol md={6}>
          <MDBInput
            id="institution"
            label="Institution"
            name="institution"
            onChange={formik.handleChange}
            value={formik.values.institution}
          />
          {formik.errors.institution ? (
            <div className="text-danger">{formik.errors.institution}</div>
          ) : null}
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-3">
        <MDBCol md={6} className="pt-2">
          <MDBSwitch
            label="Direct billing"
            checked={formik.values.directbilling ?? false}
            onChange={({ target }) =>
              formik.setFieldValue(target?.name, target?.checked)
            }
            name="directbilling"
          />
          {formik.errors.directbilling ? (
            <div className="text-danger">{formik.errors.directbilling}</div>
          ) : null}
        </MDBCol>
        <MDBCol md={6}>
          <AsyncInput
            loadOptions={loadRorOptions}
            setValue={(value) => formik.setFieldValue("ror", value)}
            defaultInputValue={formik.values?.ror}
          />
          {formik.errors.ror ? (
            <div className="text-danger">{formik.errors.ror}</div>
          ) : null}
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-3">
        <MDBCol md={6}>
          <MDBInput
            id="maildomain"
            label="Mail domain"
            name="maildomain"
            onChange={formik.handleChange}
            value={formik?.values?.maildomain}
          />
          {formik.errors.maildomain ? (
            <div className="text-danger">{formik.errors.maildomain}</div>
          ) : null}
        </MDBCol>
        <MDBCol md={6}>
          <MDBInput
            id="dealid"
            label="Deal ID"
            name="dealid"
            onChange={formik.handleChange}
            value={formik?.values?.dealid}
          />
          {formik.errors.dealid ? (
            <div className="text-danger">{formik.errors.dealid}</div>
          ) : null}
        </MDBCol>
      </MDBRow>
    </FormModalWrapper>
  );
};

export default AdminCreateDealModal;
