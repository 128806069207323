import React, { useEffect, useState } from "react";
import { MDBCol, MDBInput, MDBRow, MDBSwitch } from "mdb-react-ui-kit";
import { useFormik } from "formik";
import useAdminDeals from "./hooks/useAdminDeals";
import * as Yup from "yup";
import { getDeal, showErrorToast } from "../../helpers";
import {
  FormModalWrapper,
  AsyncInput,
  loadRorOptions,
} from "pubtrack-frontend-library";

const validationSchema = Yup.object({
  directbilling: Yup.boolean().required("Direct billing is required"),
  name: Yup.string().required("Name is required"),
  institution: Yup.string().required("Institution is required"),
  ror: Yup.string().required("ROR is required"),
  dealid: Yup.string().notRequired(),
  maildomain: Yup.string().notRequired(),
});

const AdminEditDealModal = ({ onClose, itemId, onSuccessSubmit }) => {
  const isOpen = !!itemId;

  const [isLoading, setIsLoading] = useState(false);

  const { handleEditDeal } = useAdminDeals({ doInitialFetch: false });

  const handleCloseModal = () => {
    onClose();
    formik.resetForm();
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await handleEditDeal(itemId, values);
        handleCloseModal();
        await onSuccessSubmit?.();
      } catch (error) {}
    },
  });

  useEffect(() => {
    const fetchDeal = async () => {
      try {
        setIsLoading(true);
        const { data } = await getDeal(itemId);
        formik.setValues({
          dealid: data?.dealid ?? "",
          directbilling: data?.directbilling ?? false,
          name: data?.name ?? "",
          institution: data?.institution ?? "",
          ror: data?.ror ?? "",
          maildomain: data?.maildomain ?? "",
        });
      } catch (error) {
        showErrorToast();
        handleCloseModal();
      } finally {
        setIsLoading(false);
      }
    };

    if (!!itemId) {
      fetchDeal();
    }
  }, [itemId]);

  return (
    <FormModalWrapper
      title="Edit Deal"
      isOpen={isOpen}
      isLoading={isLoading || formik.isSubmitting}
      onSubmit={formik.handleSubmit}
      onClose={handleCloseModal}
    >
      <MDBRow className="mb-3">
        <MDBCol md={6}>
          <MDBInput
            id="name"
            label="Name"
            name="name"
            onChange={formik.handleChange}
            value={formik?.values?.name}
          />
          {formik.errors.name ? (
            <div className="text-danger">{formik.errors?.name}</div>
          ) : null}
        </MDBCol>
        <MDBCol md={6}>
          <MDBInput
            id="institution"
            label="Institution"
            name="institution"
            onChange={formik.handleChange}
            value={formik?.values?.institution}
          />
          {formik.errors?.institution ? (
            <div className="text-danger">{formik.errors?.institution}</div>
          ) : null}
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-3">
        <MDBCol md={6} className="pt-2">
          <MDBSwitch
            label="Direct billing"
            checked={formik.values?.directbilling ?? false}
            onChange={({ target }) =>
              formik.setFieldValue(target?.name, target?.checked)
            }
            name="directbilling"
          />
          {formik.errors?.directbilling ? (
            <div className="text-danger">{formik.errors?.directbilling}</div>
          ) : null}
        </MDBCol>
        <MDBCol md={6}>
          <AsyncInput
            loadOptions={loadRorOptions}
            setValue={(value) => formik.setFieldValue("ror", value)}
            defaultInputValue={formik?.values?.ror}
          />
          {formik.errors.ror ? (
            <div className="text-danger">{formik.errors.ror}</div>
          ) : null}
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-3">
        <MDBCol md={6}>
          <MDBInput
            id="maildomain"
            label="Mail domain"
            name="maildomain"
            onChange={formik.handleChange}
            value={formik?.values?.maildomain}
          />
          {formik.errors.maildomain ? (
            <div className="text-danger">{formik.errors.maildomain}</div>
          ) : null}
        </MDBCol>
        <MDBCol md={6}>
          <MDBInput
            id="dealid"
            label="Deal ID"
            name="dealid"
            onChange={formik.handleChange}
            value={formik?.values?.dealid}
          />
          {formik.errors.dealid ? (
            <div className="text-danger">{formik.errors.dealid}</div>
          ) : null}
        </MDBCol>
      </MDBRow>
    </FormModalWrapper>
  );
};

export default AdminEditDealModal;
