import React from "react";
import Routes from "./routes";
import "./App.css";
import { ThemeProvider } from "styled-components";
import { theme, Footer, GlobalStylesV2 } from "pubtrack-frontend-library";

const customTheme = {
  ...theme,

  primaryColor: "#004975",
  secondaryColor: "#252729",

  accordionHeaderBackgroundColor: "#007aaf",
  modalHeaderBackgroundColor: "#004975",
  tabItemColor: "#8ea0b1",

  navbarLogoBackgroundColor: "#fff",
  navbarBackgroundColor: "#252729",
  navbarButtonHoverColor: "#eddd00",
  navbarButtonTextColor: "#fff",
  navbarButtonActiveTextColor: "#252729",
  navbarFontWeight: 400,
  navbarTextTransform: "none",
  navbarButtonHoverTextColor: "#fff",

  logoutButtonBackgroundColor: "#007aaf",
  logoutButtonTextColor: "#fff",

  paginationButtonBackgroundColor: "#004975",
  paginationButtonHoverBackgroundColor: "#252729",

  tableHeaderBackgroundColor: "#004975",

  searchButtonBackgroundColor: "#252729",
  defaultButtonBackgroundColor: "#252729",
  primaryButtonBackgroundColor: "#252729",
  exportButtonTextColor: "#252729",

  colorsPalette: [
    "#002242",
    "#144773",
    "#346ea4",
    "#4e8ab8",
    "#6faed2",
    "#9ecbe3",
  ],
};

const App = () => (
  <ThemeProvider theme={customTheme}>
    <GlobalStylesV2 />
    <Routes />
    <Footer />
  </ThemeProvider>
);

export default App;
